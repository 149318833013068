import React, { useState,useRef } from 'react';
import DragCanvas from "./DragCanvas";



// TODO: Refactor this infinite array handler
// From: https://stackoverflow.com/questions/14388291/how-to-get-the-previous-and-next-elements-of-an-array-loop-in-javascript

var currentIndex = 0;
function getAtIndex(arr,i) {
    if (i === 0) {
        return arr[currentIndex];
    } else if (i < 0) {
        return arr[(currentIndex + arr.length + i) % arr.length];
    } else if (i > 0) {
        return arr[(currentIndex + i) % arr.length];
    }
}

// usage
//getAtIndex(-2)

// you can even go crazy and it still works
//getAtIndex(500)



export const DragWall = ({items,children,sensitivity=2,className="DragWall"}) => {

// Keep a ref for the current x,y of the canvas
let pos = useRef({
	x : 0, // Virtual x position (for the absolute canvas of objects)
	y:0, // Virtual y postiion
});


// Keep a ref of what index corresponds to which cell
let cellCount = useRef(0);
let cellIds = useRef({});

// TODO: Variable based on wrapper size
const container = {
	width:window.innerWidth,
	height:window.innerHeight
}

// TODO: Variable based on item size or props
const cell = {
	width:500,
	height:container.height * 0.5,
	spacer:{x:200,y:50}
}

// Work out how many cols & rows fit in the viewport
let colCount = Math.ceil(container.width / (cell.width + cell.spacer.x));
let rowCount = Math.ceil(container.height / (cell.height + cell.spacer.y));

// State for the bounds (calculated when the canvas moves)
// North & west cells will be used for negative x,y values
// South & east cells will be used for positive x,y values
const [boundsState,setBounds] = useState({
	north : 0,
	south:rowCount,
	east : colCount,
	west : 0
});

// Non-state object maps state values to basic
// TODO: Could refactor this...
let bounds = {
	north: boundsState.north,
	south : boundsState.south,
	east: boundsState.east,
	west : boundsState.west
}

// What are the north/west most cells?
// This is the column and row we start at
let colStart = -bounds.west;
let rowStart = -bounds.north;

// What are the south/east most cells?
// Essentially take the north/westerly bound and add the easterly bound to it
// Or take the north westerly bound and add the column / row counts
// This will tell us which column we end at
let colsEnd = bounds.east - (-bounds.west);
let rowsEnd = bounds.south - (-bounds.north);


// Array of all the cells to draw
let cells = [];

// Now we check which cell sare actually in the visible viewport
// (we don't need to draw the others)

// Cells inside the min & max boundshere are the ones we will actually render,
// Even if there are more in the array

// How many cells can we fit into the north/west position?
let minBounds = {
	col : -(Math.ceil(pos.current.x / (cell.width + cell.spacer.x) )),
	row : -(Math.ceil(pos.current.y / (cell.height + cell.spacer.y)))
};

// Based on our viewports capacity we can work out which row and column cells are no longer visible
let maxBounds = {
	col : minBounds.col + colCount,
	row : minBounds.row + rowCount
};

// Loop through every cell...
// Columns first, from our north/west position
for(let c = colStart;c < colsEnd;c++){

	// Then the rows
	for(let r = rowStart;r < rowsEnd;r++){
		

		// Store a uniqu id for this cell
		let cellId = `${c},${r}`;
		// If it's not already in the index of cell IDs, store it there (this will always ensure the same cell maps to the same index number)
		if(!cellIds.current.hasOwnProperty(cellId)) cellIds.current[cellId] = cellCount.current;

		// If this column is in the visible set of cells
		if((c >= minBounds.col) && (c <= maxBounds.col)){
		if((r >= minBounds.row) && (r <= maxBounds.row)){



				// Push the data onto the array (so it will be drawn)
				// Create an ID, col & row ref, and position to place it
				cells.push({
					id : cellId,
					col:c,
					row:r,
					x : (c * (cell.width + cell.spacer.x)),
					y : r * (cell.height + cell.spacer.y),
					ix : cellIds.current[cellId]
				});
			}
		}

		// Increment the cell count
		cellCount.current += 1;
	
	}

}

	// Now render the cells

  return (
  		<div className={className}>
      	<DragCanvas sensitivity={{
          drag : sensitivity,
          wheel : 1
        }} onRest={
          ({x,y}) => {

              // Move all the cells back to a zero-based position (subtract canvas x for all the x values, subtract canvas y form all the y values)
              // Set the canvas to 0,0

              /*setAdjust({
                x: -500,
                y: -500
              })*/

             

          }
        } 

        onMove={({x,y})=>{
      		
      		// onMove update callback

      		// Update the useRef variable
      		pos.current = {
      			x,y
      		}

      		// Get the values in state
      		let northCells = boundsState.north;
      		let eastCells = boundsState.east;
      		let southCells = boundsState.south;
      		let westCells = boundsState.west;

      		// If the canvas x pos is positive, we have moved to the right, we need to display cells to the west of the viewport
      		if(x > 0){
      			
      			westCells = Math.ceil(Math.abs(x) / (cell.width + cell.spacer.x));

      		// Otherwise, we have moved left so need to display cells to the east of the viewport
      		}else{
      			eastCells = Math.ceil((container.width + Math.abs(x)) / (cell.width + cell.spacer.x));
      			
      		}

      		// If the y is positive, we have moved down so need to display cells to the north
      		if(y > 0){
      			
      			northCells = Math.ceil(Math.abs(y) / (cell.height + cell.spacer.y));

      		// Otherwise we have moved up so need to display cells to the south
      		}else{
      			southCells = Math.ceil((container.height + Math.abs(y)) / (cell.height + cell.spacer.y));
      		
      		}

      		// If any of the cells have changed compared to the state, update the state
      		if((northCells !== boundsState.north) || (southCells !== boundsState.south) || (eastCells !== boundsState.east) || (westCells !== boundsState.west)){
      			
      			setBounds({
      				north : northCells,
      				south : southCells,
      				east : eastCells,
      				west : westCells
      			});
      		}


      	}}>

      		{cells.map(
      			(c) => {

              let item = getAtIndex(items,c.ix);

      				return <div data-col-ix={((c.col + 1) % 2 === 0) ? 'even' : 'odd'} data-row-ix={((c.row + 1) % 2 === 0) ? 'even' : 'odd'}  key={c.id} style={{position:'absolute',left:c.x,top:c.y,width:cell.width,height:cell.height,backgroundoff:`#333333`}}>

                {children({item,cell:c})}
		       
      				</div>
      			}
      		)}
      		
	    </DragCanvas>
	    </div>
  
  );
};



