import React , {useEffect,useRef} from 'react' // useState

import { useHistory} from 'react-router-dom'; // NavLink, Link, useLocation

//import { AnimatePresence, motion } from "framer-motion";
//import { Media } from 'react-breakpoints';
//import {Helmet} from "react-helmet";

import isDark from 'is-dark-color'

/*

Local

*/

import {DragWall} from './components/DragWall.js';
//import {PrismicAPI} from './components/Prismic.js';
//import {Tooltip} from './components/Tooltip.js';
import {TouchClass} from './components/IsTouch.js'; //isTouch,IsTouch,NotTouch
//import {Sort} from './components/Sort.js';
import {Loader} from './components/Loader.js';
//import {useBodyClass} from './hooks/useBodyClass.js';

/*

Styles

*/

import './App.scss';

/*

Assets

*/



function genColor (seed) {
    
    let color;
    color = Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215);
    color = color.toString(16);
    // pad any colors shorter than 6 characters with leading 0s
    while(color.length < 6) {
        color = '0' + color;
    }
    
    return color;
}


/*function Meta({data}){

  let matchKey = Object.keys(data).filter((k)=>(window.location.href.match(k))).pop();
  if(!matchKey) return null;

  let metaData = data[matchKey];

  return <Helmet>
           
            <title>{metaData.title}</title>
            <meta
            name="description"
            content={metaData.description}  />

        </Helmet>


}*/


function App() {

  const history = useHistory();
  //const location = useLocation();

  /*const [state,setState] = useState(
    {
      quotes : [],
      shuffled : []
    }
  );*/

  //const [menuOpen,setMenuOpen] = useState(false);
  
  //const [showContent,setShowContent] = useState(false);
  //const [seenIntro,setSeenIntro] = useState(location.hash !== '');

  //const [seenTooltip,setSeenTooltip] = useState(false);

  const pageviews = useRef(0);

  useEffect(() => {
    history.listen((args)=>{
      pageviews.current++;
    })
  }, [history]);

  //let isHome = location.pathname === '/';
  //let reading = window.location.hash.match('read');
  
  //let isFirstPage = pageviews.current === 0;

  //let hideNav = isHome && isFirstPage && !seenIntro;
  //let proxy = `//proxy.olson.work?p=`;

  return (
    <TouchClass>
      <h1 className="Splash-title">Materia.</h1>

      <Loader manifest={[
      {
        name:'stories',
        src:'https://materia.news/.netlify/functions/data?table=Stories',type:'json'
      },
      {
        name:'materials',
        src:'https://materia.news/.netlify/functions/data?table=Materials',type:'json'
      },
      {
        name:'people',
        src:'https://materia.news/.netlify/functions/data?table=People',type:'json'
      },
      {
        name:'categories',
        src:'https://materia.news/.netlify/functions/data?table=Categories',type:'json'
      },
      {
        name:'themes',
        src:'https://materia.news/.netlify/functions/data?table=Themes',type:'json'
      }

      ]} progress={()=><div />}>
      {
        ({stories}) => (

            <DragWall className="DragWall" items={stories}>
            {
              ({item,cell}) => {

                let hasImage = item.fields.Image && item.fields.Image.length;

                let titleParts = item.fields.Name ? item.fields.Name.split(' - ') : [];

                let portrait = false;

                if(hasImage && item.fields.Image[0].thumbnails){
                  console.log(item.fields.Image[0]);
                  portrait = item.fields.Image[0].thumbnails.large.height > item.fields.Image[0].thumbnails.large.width;
                }

                let style = null;
                let isDarkColor = false;
                let long = false;

                if(!hasImage){

                  let bg = genColor(cell.ix);
                  isDarkColor = isDark(`#${bg}`);
                  style={background:`#${bg}`};

                  long = item.fields.Name ? item.fields.Name.length > 20 : false;
                }

                let alt = item.fields.Name ? item.fields.Name : 'Image from Materia';

                return (
                <div className={`Tile Tile--${long ? 'long' : 'short'} Tile--${isDarkColor ? 'dark' : 'light'} Tile--${hasImage ? 'hasImage' : 'noImage'} Tile--${portrait ? 'portrait' : 'landscape'}`}>
                  <div className="Tile-inner" style={style}>

                    {
                      (()=>{
                        
                        if(hasImage){
                          return <img src={item.fields.Image[0].url} alt={alt} />
                        }
                      })()
                    }
                    <h3 className="Tile-heading"><span>{titleParts[0]}</span> {(titleParts.length > 1) ? '—' : ''} {titleParts[1]}</h3>
                    
                  </div>
                </div>
              )}
            }
            </DragWall>
        )
      }
      </Loader>
    
    </TouchClass>
  );
}



export default App;
